import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonPad from './Button';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputMask from "react-input-mask";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const Content = styled.div`
  margin: auto;
  margin-top: 1rem;
  width: 70%;

  @media screen and (max-width: 1180px) {
    width: 80%;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 28px;
  margin: auto;
  width: 90%;
  padding: 10px;
`;

const Termos = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const ErrorData = styled.div`
  color: red;
`;

const TermoLabel = styled.label`
  font-weight: bold;
  color: black;
`;

const TermosUso = styled.a`
  color: #949494;

  &:link {
    text-decoration: underline;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: #fadc00;
    text-decoration: underline;
  }

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
`;

const CheckBoxField = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  margin: 1rem 0;
  width: 100%;
`;

const Btn = styled(ButtonPad)`
  border-radius: 15px;
  padding: 15px 30px;
  transition: 0.3s;
  font-size: 1rem;
`;

const S3ApiUpload = process.env.REACT_APP_LAMBDA_API;

const uploadAnswer = (user_info, answerHistory) => {
  axios
    .put(S3ApiUpload, {
      userInfo: user_info,
      respostas: answerHistory,
    }).catch((error) => console.error(error));
};

const checkDigitsCalc = (col_values, digit) => {
  var sum = 0
  var quadro_pesos = []
  if (digit === 1) {
    quadro_pesos = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  } else {
    quadro_pesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  }
  for (var i = 0; i < quadro_pesos.length; i++) {
    sum += (parseInt(col_values[i]) * quadro_pesos[i])
  }

  return sum
};

const VerifyDigit = (resto) => {
  var dg1 = 0
  if (resto % 11 < 2) {
    dg1 = 0
  } else {
    dg1 = 11 - resto
  }
  return dg1
};

const CalculateVerifyDigits = (number) => {
  var cnpj = number.substr(0, 12)
  var dgVerificadores = 0
  dgVerificadores += checkDigitsCalc(cnpj, 1)

  var restoFirstDigit = dgVerificadores % 11
  
  var firstDigit = VerifyDigit(restoFirstDigit)
  
  cnpj += firstDigit
  
  var dgVerificadoresSecond = checkDigitsCalc(cnpj, 2)
  var restoSecondDigit = dgVerificadoresSecond % 11
  var secondDigit = VerifyDigit(restoSecondDigit)
  cnpj += secondDigit
  
  return cnpj
  
};

const ValidadeCnpj = (cnpj) => {
  cnpj = cnpj.replace(/[^0-9]/g, '');
  if (cnpj.length !== 14)
    return false;

  var verifiedCnpj = CalculateVerifyDigits(cnpj)
  
  if (verifiedCnpj !== cnpj) {
    return false
  }
  return true;
};

const Formulario = ({ answerHistory, setEnd }) => {
  const classes = useStyles();
  const [termo, setTermo] = useState(false);

  const handleCheckBoxChange = (event) => {
    setTermo(event.target.checked);
  };

  const parseAnwserAndTermo = (answerHistory) => {
    let _answerHistory = Object.fromEntries(
      Object.entries(answerHistory).map(([k, v]) => [
        k,
        {
          ...{
            resp: Array.from(v.resp),
          },
        },
      ])
    );
    return _answerHistory;
  };


  return (
    <Content>
      <Title>Preencha os dados para finalizar</Title>
      <Formik
        initialValues={{
          razaoSocial: '',
          cnpj: '',
          nome: '',
          email: '',
          telefone: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Email inválido';
          }
          if (!ValidadeCnpj(values.cnpj)) {
            errors.cnpj = 'CNPJ inválido';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!termo) {
            alert('Você precisa aceitar os termos');
            setSubmitting(false);
          } else {
            setTimeout(() => {
              uploadAnswer(values, parseAnwserAndTermo(answerHistory));
              setEnd(true);
              // emailjs.send("service_a0reucr", "template_cqb2x8q", {
              //     from_name: "jonas",
              //     message: values,
              //     reply_to: "asd",
              // }, "user_fSbYlijFnQxdWV1iceZBJ");
              setSubmitting(true);
            }, 400);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className={classes.root}>
            <TextField
              fullWidth
              required
              id="razaoSocial"
              name="razaoSocial"
              label="Razão Social"
              value={values.razaoSocial}
              onChange={handleChange}
              error={touched.razaoSocial && Boolean(errors.razaoSocial)}
              helperText={touched.razaoSocial && errors.razaoSocial}
            />
            {errors.razaoSocial && touched.razaoSocial ? (
              <ErrorData>{errors.razaoSocial}</ErrorData>
            ) : null}

            <InputMask
            mask="99.999.999/9999-99"
            value={values.cnpj}
            onChange={handleChange}
            className="cnpj"
          >
              {() => <TextField
              fullWidth
              required
              error
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              error={touched.cnpj && Boolean(errors.cnpj)}
              helperText={touched.cnpj && errors.cnpj}
              />}
            </InputMask>
            
            <TextField
              fullWidth
              required
              id="nome"
              name="nome"
              label="Nome"
              value={values.nome}
              onChange={handleChange}
              error={touched.nome && Boolean(errors.nome)}
              helperText={touched.nome && errors.nome}
            />
            
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            
            <InputMask
            mask="(99)99999-9999"
            value={values.telefone}
            onChange={handleChange}
            className="telefone"
          >
              {() => <TextField
              fullWidth
              id="telefone"
              name="telefone"
              label="Telefone"
              error={touched.telefone && Boolean(errors.telefone)}
              helperText={touched.telefone && errors.telefone}
              />
              }
          </InputMask>
            <Termos>
              <CheckBoxField>
                <FormGroup
                  style={{ alignItems: 'center', marginRight: '-28px' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termo}
                        onChange={handleCheckBoxChange}
                        name="termosAceitos"
                      />
                    }
                  />
                </FormGroup>
                <TermoLabel>
                  Li e aceito os{' '}
                  <TermosUso
                    href="https://www.abla.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termos de Uso
                  </TermosUso>
                  .
                </TermoLabel> 
              </CheckBoxField>
              <Btn type="submit" disabled={isSubmitting}>
                Enviar
              </Btn> 
            </Termos> 
          </form>
        )}
      </Formik>
    </Content>
  );
};

export default Formulario
