var questions = [
  {
    id: 'main1',
    pergunta: 'Qual o principal segmento que você atua?',
    explicacao: 'Selecione apenas um',
    max_options: 1,
    opcoes: [
      { resp: 'Rent a Car', prox_id: 'frota' },
      { resp: 'Terceirização de frota', prox_id: 'frota' },
      { resp: 'Carro por Assinatura', prox_id: 'frota' },
      { resp: 'Aluguel para apps', prox_id: 'frota' },
      { resp: 'Outros', prox_id: 'frota' },
    ],
  },
  {
    id: 'frota',
    pergunta: 'Qual o tamanho da sua frota?',
    max_options: 1,
    opcoes: [
      { resp: '1-10 veículos', prox_id: 'veiculos' },
      { resp: '11-50 veículos', prox_id: 'veiculos' },
      { resp: '51-300 veículos', prox_id: 'veiculos' },
      { resp: '301-600 veículos', prox_id: 'veiculos' },
      { resp: 'Acima de 600 veículos', prox_id: 'veiculos' },
    ],
  },
  {
    id: 'veiculos',
    pergunta: 'Quais veículos você tem em sua frota?',
    explicacao: 'Selecione os três principais',
    max_options: 3,
    opcoes: [
      { resp: 'Veículos Leves', prox_id: 'funcionarios' },
      { resp: 'Caminhonetes', prox_id: 'funcionarios' },
      { resp: 'Furgões', prox_id: 'funcionarios' },
      { resp: 'Vans', prox_id: 'funcionarios' },
      { resp: 'Caminhões', prox_id: 'funcionarios' },
      { resp: 'Motocicletas', prox_id: 'funcionarios' },
      { resp: 'Ônibus', prox_id: 'funcionarios' },
      { resp: 'Outros', prox_id: 'funcionarios' },
      { resp: 'Seguir', prox_id: 'funcionarios' },
    ],
  },
  {
    id: 'funcionarios',
    pergunta: 'Quantos funcionários você tem em sua empresa?',
    explicacao: 'Selecione apenas uma faixa',
    max_options: 1,
    opcoes: [
      { resp: '1-5', prox_id: 'website' },
      { resp: '6-10', prox_id: 'website' },
      { resp: '11-15', prox_id: 'website' },
      { resp: '16-20', prox_id: 'website' },
      { resp: '21-25', prox_id: 'website' },
      { resp: '26-50', prox_id: 'website' },
      { resp: '51-200', prox_id: 'website' },
      { resp: 'Acima de 200', prox_id: 'website' },
    ],
  },
  {
    id: 'website',
    pergunta: 'Sua empresa possui website?',
    explicacao: 'Selecione apenas uma resposta',
    max_options: 1,
    opcoes: [
      { resp: 'SIM', prox_id: 'crm' },
      { resp: 'NÃO', prox_id: 'crm' },
    ],
  },
  {
    id: 'crm',
    pergunta: 'Sua empresa utiliza CRM/ERP ou algum tipo de controle?',
    explicacao: 'Selecione apenas um',
    max_options: 1,
    opcoes: [
      { resp: 'SAP', prox_id: 'formulario' },
      { resp: 'Locavia', prox_id: 'formulario' },
      { resp: 'Euroit', prox_id: 'formulario' },
      { resp: 'Sistema Autobahn', prox_id: 'formulario' },
      { resp: 'Controle em Excel', prox_id: 'formulario' },
      { resp: 'Controle em Trello', prox_id: 'formulario' },
      { resp: 'Outro', prox_id: 'formulario' },
    ],
  },
];

export default questions;
