import styled from 'styled-components'


export const Button = styled.button `
    border-width: 0;
    border-radius: 15px;
    padding: 15px 30px;
    text-decoration: none;
    color: #6a6d71;
    background-color: #FFF200;
    transition: 0.3s;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    user-select: none;

    &:hover {
        color: gray;
        background-color: #bbbbbb54;
    }
`;

export default Button
