import React, { useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import styled from 'styled-components';
import questions from '../questionnaire/questions';
import Formulario from './Formulario';
import { FaArrowLeft } from 'react-icons/fa';


const QuizWindow = styled.div`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  margin-top: 10vh;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 1em auto;
  @media screen and (min-width: 1180px) {
    width: 50%;
  }
`;

const Option = styled.button`
  border-width: 0;
  display: block;
  border-radius: 15px;
  padding: 15px 30px;
  text-decoration: none;
  color: #3f3f3f;
  background-color: ${(props) => (props.selected ? '#e9e9e9' : '#fff200')};
  transition: 0.3s;
  font-size: 1em;
  outline: none;
  user-select: none;
  margin-top: 1em;
  cursor: pointer;
  @media screen and (min-width: 1180px) {
    &:hover {
      color: gray;
      background-color: #e9e9e9;
    }
  }
`;

const Question = styled.h3`
  width: 70%;
  margin: 0 auto;
  padding-bottom: 10px;
`;

const Explanation = styled.p`
`;

const Itens = styled.div`
  display: inline-flex;
  float: left;
  margin-top: 1rem;
  color: #404040;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: gray;
  }
`;

const Buttton = styled.div`
  margin-left: 10px;
`;

const Arrow = styled(FaArrowLeft)`
  margin-left: 5px;
`;



/*
estrutura answerHistory

{main2: {resp: [resp1, resp2, resp3], prev_id: main1}}

*/

// id da pergunta inicial
const firstQuestionId = 'main1';

export default function Quiz(props) {
  // States
  const [answerHistory, setAnswerHistory] = useStateWithCallbackLazy(() => {
    let _answerHistory;

    // Procura instância no localStorage para preencher histórico de respostas
    // Caso ainda não exista recebe objeto vazio
    if (localStorage.hasOwnProperty('respostas')) {
      _answerHistory = JSON.parse(localStorage.getItem('respostas'));
      // Tranforma os Arrays com string de respostas em Sets
      Object.keys(_answerHistory).map(
        (keys) =>
          (_answerHistory[keys].resp = new Set(_answerHistory[keys].resp))
      );
    } else {
      _answerHistory = {};
    }

    return _answerHistory;
  });

  const [question, setQuestion] = useState(() => {
    return {
      ...questions[questions.findIndex((x) => x.id === firstQuestionId)],
      ...{ prev_id: '' },
    };
  });

  const changeQuestionFromId = (nextId) => {
    // Procura no JSON questions qual objeto possui a id indicada pela resposta anterior
    // Atribui objeto encontrado à nova questão
    if (nextId !== 'formulario') {
      answerHistory.hasOwnProperty(nextId)
        ? setQuestion(() => ({
            ...questions[questions.findIndex((x) => x.id === nextId)],
            ...{ prev_id: answerHistory[nextId].prev_id },
          }))
        : setQuestion(() => ({
            ...questions[questions.findIndex((x) => x.id === nextId)],
            ...{ prev_id: question.id },
          }));
    } else {
      setQuestion(() => {
        return { ...{ id: nextId, prev_id: question.id } };
      });
    }
  };

  const changeQuestionAfterMaxOptions = function (nextId) {
    if (answerHistory[question.id].resp.size >= question.max_options) {
      changeQuestionFromId(nextId);
    }
  };

  const goBack = function () {
    // Checa qual a pergunta anterior na ordem de decisão
    // Atribui ela à questão atual
    changeQuestionFromId(question.prev_id);
  };

  const clickHandler = (opcao) => {
    // Para saber se muda ou não pergunta:
    // Compara tamaho do set com respostas com atributo max_options
    !answerHistory.hasOwnProperty(question.id)
      ? setAnswerHistory(
          (old) => {
            old[question.id] = {
              resp: new Set([opcao.resp]),
              prev_id: question.prev_id,
              max_options: question.max_options,
            };
            return { ...old };
          },
          () => changeQuestionAfterMaxOptions(opcao.prox_id)
        )
      : answerHistory[question.id].resp.has(opcao.resp)
      ? setAnswerHistory((old) => {
          old[question.id].resp.delete(opcao.resp);
          return { ...old };
        })
      : answerHistory[question.id].resp.size <
          answerHistory[question.id].max_options &&
        setAnswerHistory(
          (old) => {
            old[question.id].resp.add(opcao.resp);
            return { ...old };
          },
          () => changeQuestionAfterMaxOptions(opcao.prox_id)
        );
  };

  useEffect(() => {
    // Transforma Sets em Arrays antes de enviar para local storage
    let _answerHistory = Object.fromEntries(
      Object.entries(answerHistory).map(([k, v]) => [
        k,
        {
          ...{
            resp: Array.from(v.resp),
            prev_id: v.prev_id,
            max_options: v.max_options,
          },
        },
      ])
    );
    localStorage.setItem('respostas', JSON.stringify(_answerHistory));
  }, [answerHistory]);

  return question.id !== 'formulario' ? (
    <QuizWindow>
      <>
        <Question>{question.pergunta}</Question>
        <Explanation>{question.explicacao}</Explanation>

        <Options>
          {question.opcoes.map((opcao) => (
            <Option
              selected={
                answerHistory.hasOwnProperty(question.id) &&
                answerHistory[question.id].resp.has(opcao.resp)
              }
              key={opcao.resp}
              onClick={() => clickHandler(opcao)}
            >
              {opcao.resp}
            </Option>
          ))}
          {question.prev_id !== '' ? (
            <Itens onClick={() => goBack()}>
              <Arrow />
              <Buttton>Voltar</Buttton>
            </Itens>  
          
        ) : null}
        </Options>
      </>
    </QuizWindow>
  ) : (
      <> 
        <Formulario
          setEnd={props.setEnd}
          answerHistory={answerHistory}
          setAnswerHistory={setAnswerHistory}
          />
      </>
    
  );
}
