import React, { useState } from 'react'
import styled from 'styled-components';
import './App.css'
import logo from './logo.png';
import Start from './components/Start'
import Quiz from './components/Quiz';
import End from './components/End';

const Container = styled.div`
  height: 5px;
  width: 100%;
  position: relative;
  top: 20px;
`;
const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  transition: width 10s ease-in-out;
`;

const Background = styled(BaseBox)`
  background: grey;
  width: 100%;
`;

const Progress = styled(BaseBox)`
  background: yellow;
  width: ${({ percent }) => percent}%;
`;

function App() {

  const [start, setStart] = useState(false);
  const [percent, setPercent] = useState(0);
  const [end, setEnd] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Container>
          <Background />
          <Progress percent={percent} />
        </Container>
      </header>

      <div className="quiz">
        {start ? ( 
           end ? <End setEnd={setEnd}/> :
          <Quiz setPercent={setPercent} setEnd={setEnd} />
        ) : ( 
          <Start setStart={setStart} />
        )} 


      </div>
    </div>
  );
}

export default App;
