import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';


const Intro = styled.div`
  margin: 3em auto;
  text-align: center;
  @media screen and (max-width: 1180px) {
    margin: 2em auto;
  }
`;

const Quizz = styled.p`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  font-weight: bold;
  margin-bottom: 15px;
  @media screen and (max-width: 1180px) {
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  margin-bottom: 15px;
  @media screen and (max-width: 1180px) {
    margin-bottom: 5px;
  }
`;

const Content = styled.div`
    margin: auto;
    width: 100%;
    padding: 10px;
`;

const Border= styled.div` 
    display: inline-block;
    align-items: center;
    width: 250px;
    color: #7C8083;
    background-color: #FFF200;
    padding: 40px;
    box-shadow: 7px 5px 5px gray;
    border-radius: 15px;
    font-weight: bold;

    &:hover {
        color: gray;
        background-color: #e9e9e9;
    }
    @media only screen and (max-width: 600px) {
        width: 200px;
        margin: 1rem;
      }
`;

const Card = styled.div`
  display: inline-block;
  width: 300px;
  height: 50%;
`

const ButtonCard = styled.p`
    border-radius: 15px;
    margin-top: 20px;
`;

const thumbs = css`
  font-size: 80px;
  @media only screen and (max-width: 600px) {
    font-size: 40px;
  }
`;

const Start = ({ setStart }) => {
  const startQuiz = () => setStart(true);
  return (
    <Intro>
      <Quizz>Censo do Setor de Locação de Automóveis 2021</Quizz>
      <Subtitle> Vamos começar?</Subtitle>
      <Content>
        <Card>
          <Border onClick={startQuiz}>
            <FaThumbsUp css={thumbs} />
            <ButtonCard> Sim</ButtonCard>
          </Border>
        </Card>
        <Card>
          <Border onClick={startQuiz}>
            <FaThumbsDown css={thumbs} />
            <ButtonCard> Não</ButtonCard>
          </Border>
        </Card>
      </Content>
    </Intro>
  );
};

export default Start
