import React from 'react'
import styled from 'styled-components/macro'
import Button from './Button.js'


const All = styled.div`
    margin: 3em auto;
    text-align: center;
    @media screen and (max-width: 1180px) {
        margin: 3em auto;
        align-items: center;
    }
`;

const Thanks = styled.p`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  font-weight: bold;
  margin-bottom: 30px;
  
`;
const Link = styled.a`

`;

function End () {

    return(
        <All>
            <Thanks>Obrigado pela sua participação!</Thanks>
            <Link href="http://abla.com.br/">
            <Button>Voltar ao site da Abla</Button> 
            </Link>
        </All>
    );
}



export default End